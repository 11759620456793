$(document).ready(function() {
    $(".card").matchHeight();
    $(".umb-grid").fitVids();

    //filter categories module
    if ($('.categoryButtons a').length > 0) { 
        setSelectedTags();
     
        $('.categoryButtons a').on("click", function (e) {
            
            currentElem = $(this);
            if (currentElem.attr('id') === "allcategories") {
                if (currentElem.children('i').hasClass('hide')) {
                    $('#allcategories').children('i').removeClass('hide');
                    $('.categoryButtons a').children('i').addClass('hide');
                    currentElem.children('i').removeClass('hide');
                    setSelectedTags();
                }
                else {
                    setSelectedTags();
                    
                    

                }

            }
            else {
                if (currentElem.children('i').hasClass('hide')) {
                    
                    $('#allcategories').children('i').addClass('hide');
                    currentElem.children('i').removeClass('hide');
                    $('.' + currentElem.attr('id')).removeClass('hide');  
                    setSelectedTags();
                }
                else {
                    $('.' + currentElem.attr('id')).addClass('hide');  
                    $('#allcategories').children('i').addClass('hide');
                    currentElem.children('i').addClass('hide');
                    setSelectedTags();                   
                }
            }
            setSelectedTags();
            e.PreventDefault;
        });
    }
    function setSelectedTags() {
        $('#selectedTagHolder').html("");
        allSelectedtags = "";
        $.each($('.categoryButtons a'), function () {
            if (!$(this).children('i').hasClass('hide')) {
                $('#selectedTagHolder').append($(this).attr('id') + ',');
                allSelectedtags = allSelectedtags + $(this).attr('id') + ',';
            }
        });

        allSelectedtags = allSelectedtags.substring(0, allSelectedtags.length - 1);
        
        if (allSelectedtags !== "allcategories" && allSelectedtags !== "") {
            selectedArray = allSelectedtags.split(',');

            $('.documentsList .card').parent().addClass('hide');
            var index;
            for (index = 0; index < selectedArray.length; ++index) {
                
                $('.' + selectedArray[index]).removeClass('hide');
            }
            $(".card").matchHeight();
        } else if (allSelectedtags === "allcategories" || allSelectedtags === ""){
            $('#allcategories').children('i').removeClass('hide');
            $('.documentsList .card').parent().removeClass('hide');
        }
    }
});

